@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap");

.route-details-container {
  .route-description {
    padding: 20px 290px;
  }
  p {
    font-family: "Dancing Script", cursive;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    font-size: 1.6rem;
    color: #666;
    line-height: 1.6;
  }
}
@media (max-width: 1210px) {
  .route-details-container {
    .route-description {
      padding: 20px 90px;
    }
  }
}

@media (max-width: 551px) {
  .route-details-container {
    .route-description {
      padding: 20px 40px;

      p {
        font-size: 1.1rem;
        line-height: 1.4;
      }
    }
  }
}
