.TA_cdsratingsonlynarrow {
  position: relative;
  z-index: 100;
  width: 9px;
  top: 12.2rem;
  left: 65rem;
}

@media (max-width: 2166px) {
  .TA_cdsratingsonlynarrow {
    left: 72rem;
  }
}

@media (max-width: 1499px) {
  .TA_cdsratingsonlynarrow {
    left: 52rem;
  }
}

@media (max-width: 991px) {
  .TA_cdsratingsonlynarrow {
    left: 42rem;
  }
}
@media (max-width: 829px) {
  .TA_cdsratingsonlynarrow {
    left: 32rem;
  }
}
@media (max-width: 829px) {
  .TA_cdsratingsonlynarrow {
    left: 27rem;
  }
}

@media (max-width: 570px) {
  .TA_cdsratingsonlynarrow {
    left: 23rem;
  }
}

@media (max-width: 530px) {
  .TA_cdsratingsonlynarrow {
    margin-bottom: 6rem;
    top: 2rem;
    left: 13rem;
  }
}

@media (max-width: 468px) {
  .TA_cdsratingsonlynarrow {
    left: 11rem;
  }
}
@media (max-width: 418px) {
  .TA_cdsratingsonlynarrow {
    left: 10rem;
  }
}

@media (max-width: 399px) {
  .TA_cdsratingsonlynarrow {
    left: 8rem;
  }
}
