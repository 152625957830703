.newsletter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
}

.newsletter-section {
  width: 70%;
  margin-top: 5em;
  margin-left: -5em;
  position: relative;
  height: 20em;
  border: 3px ridge #094779;
  outline: none;
  background-color: transparent;
  color: white;
  transition: 1s;
  border-radius: 0.9em;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.newsletter-section::after {
  content: "";
  position: absolute;
  top: -20px;
  left: 3%;
  width: 94%;
  height: 10%;
  background-color: #fff;
  transition: 0.5s;
  transform-origin: center;
}

.newsletter-section::before {
  content: "";
  transform-origin: center;
  position: absolute;
  top: 95%;
  left: 3%;
  width: 94%;
  height: 10%;
  background-color: #fff;
  transition: 0.5s;
}

.newsletter-section:hover::before,
.newsletter-section:hover::after {
  transform: scale(0);
}

.newsletter-section:hover {
  box-shadow: inset 0px 0px 65px #1479ea;
}

.bg-img img {
  margin-top: -13px;
  position: absolute;
  width: 50%;
  height: 200px;
  opacity: 0.8;
  z-index: 1;
}

.bg-img .onscroll {
  width: 100%;
  height: 10em;
  margin-top: 14em;
  opacity: 0;
  transform: translateY(20px); // Adjust as needed for the effect
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.bg-img .onscroll.visible {
  opacity: 1;
  transform: translateY(0);
}

.newsletter-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 110px;
  // padding: 70px 400px; /* Add some padding to create space around the content */
  border-radius: 8px; /* Optional: Add rounded corners */
  position: relative;
  z-index: 2;

  .text {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    color: rgba(255, 0, 0, 0.877);
  }

  .small-text {
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 15px;
    text-transform: uppercase;
    color: rgba(183, 3, 3, 0.911);
  }

  .big-text {
    color: #000000;
    margin-bottom: 20px;
    font-size: 25px;
    line-height: 30px;
    font-weight: 540;
    text-transform: uppercase;
    padding: 0 30px;
  }

  .form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    width: 530px;
    height: 40px;
    border-radius: 80px;
    border: 1px solid #e3e3e3;

    input {
      width: 200px;
      margin-left: 30px;
      border: none;
      outline: none;
      color: #616161;
      font-family: Poppins;
      font-size: 15px;
    }

    button {
      width: 120px;
      height: 40px;
      border-radius: 80px;
      background: black;
      color: white;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .social-icons {
    display: flex;
    margin-top: 20px;
    gap: 20px;

    .icon {
      width: 50px;
      height: 50px;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      transition: background 0.3s;

      &:hover {
        background: rgb(0, 217, 255);
      }
    }
  }
}

ul {
  list-style: none;
}

.example-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.example-2 .icon-content {
  margin: 0 -90px;
  position: relative;
  padding: 0.5rem;
}

.example-2 .icon-content .tooltip {
  position: absolute;
  top: 100%;
  right: 110%;
  transform: translateY(200%);
  color: #fff;
  padding: 6px 10px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  transition: all 0.3s ease;
}

.example-2 .icon-content:hover .tooltip {
  opacity: 1;
  visibility: visible;
  top: -50px;
}

.example-2 .icon-content a {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #4d4d4d;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.example-2 .icon-content a:hover {
  box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
}

.example-2 .icon-content a svg {
  position: relative;
  z-index: 1;
  width: 25px;
  height: 25px;
}

.example-2 .icon-content a:hover {
  color: white;
}

.example-2 .icon-content a .filled {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #000;
  transition: all 0.3s ease-in-out;
}

.example-2 .icon-content a:hover .filled {
  height: 100%;
}

.example-2 .icon-content a[data-social="facebook"] .filled,
.example-2 .icon-content a[data-social="facebook"] ~ .tooltip {
  background-color: #0274b3;
}

.example-2 .icon-content a[data-social="instagram"] .filled,
.example-2 .icon-content a[data-social="instagram"] ~ .tooltip {
  background: linear-gradient(
    45deg,
    #405de6,
    #5b51db,
    #b33ab4,
    #c135b4,
    #e1306c,
    #fd1f1f
  );
}

.example-2 .icon-content a[data-social="youtube"] .filled,
.example-2 .icon-content a[data-social="youtube"] ~ .tooltip {
  background-color: #ff0000;
}

.icons {
  display: flex;
  align-items: center;
  margin-top: -39px;
  margin-right: 100px;
  color: #094779; /* Change text color as needed */
  font-size: 14px; /* Adjust text size as needed */
  font-weight: 800;
}

.icons::before {
  content: "";
  display: block;
  width: 2px;
  height: 89px; /* Adjust line height as needed */
  background-color: #094779; /* Change line color as needed */
  margin-bottom: 10px;
}

.icons {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(360deg);
}

@media (max-width: 1124px) {
  .newsletter-content {
    .form {
      width: 340px;
    }

    .big-text {
      font-size: 20px;
    }
  }
}

@media (max-width: 768px) {
  .newsletter-section {
    .newsletter-content {
      // padding: 70px 200px;
    }

    .big-text {
      font-size: 28px;
    }

    .form input {
      width: 300px;
    }

    .form button {
      width: 120px;
    }
    .bg-img img {
      margin-top: -13px;
      position: absolute;
      width: 97%;
      height: 200px;
      opacity: 0.8;
      z-index: 1;
    }
  }
}

@media (max-width: 530px) {
  .newsletter-section .newsletter-content {
    padding: 80px 20px;

    .form {
      width: 100%;

      input {
        width: 100%;
        margin-left: 10px;
        font-size: 12px;
      }
      button {
        width: 120px;
        height: 40px;
        border-radius: 80px;
        background: black;
        color: white;
        font-size: 10px !important;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 434px) {
  .newsletter-section .newsletter-content {
    padding: 80px 10px;
    .big-text {
      font-size: 20px;
    }
  }
}
