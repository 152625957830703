@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap");

.team-container {
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  padding: 20px;

  h2 {
    margin-top: 90px;
    font-size: 34px;
    margin-bottom: 20px;
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 10%,
      rgba(9, 71, 121, 1) 41%,
      rgba(0, 212, 255, 0.8) 66%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .team-grid {
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(150px, 1fr)
    ); // Creates a responsive number of columns
    gap: 80px;
    justify-content: center;

    .team-member {
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
      // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
      border-radius: 50px;
      margin-bottom: 80px;

      img {
        width: 80px; // Fixed size for images
        height: 80px;
        border-radius: 50%; // Circular images
        object-fit: cover;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 17px;
        margin-bottom: -10px;
      }
      p {
        font-family: "Dancing Script", cursive;
        font-optical-sizing: auto;
        font-weight: 900;
        font-style: normal;
        font-size: 14px;
        margin-bottom: 1px;
      }
    }
  }
}

@media (max-width: 768px) {
  .team-container .team-grid .team-member {
    img {
      width: 80px;
      height: 80px;
    }
  }
}

@media (max-width: 480px) {
  .team-container .team-grid {
    grid-template-columns: 1fr; // Single column layout on smaller screens
  }
}
// .card {
//   width: 254px;
//   height: 190px;
//   border-radius: 50px;
//   background: #e0e0e0;
//   box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
// }
