// src/components/Routes/TrekRoutes.scss
.trek-routes {
  .routes-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }

  .route-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    text-align: center;

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    .route-info {
      padding: 15px;

      h3 {
        margin: 10px 0;
        font-size: 1.5rem;
        color: #333;
      }

      p {
        font-size: 1rem;
        color: #666;
        margin-bottom: 15px;
      }

      button {
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        padding: 10px 20px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}
.booking-days {
  display: flex;
  gap: 10px;
}
.booking-days img {
  width: 40px;
  height: 40px;
}

.booking-altitude {
  display: flex;
  gap: 10px;
}

.booking-altitude img {
  width: 50px;
  height: 50px;
}

.card-icons {
  display: flex;
  gap: 80px;
}
.detail-price {
  font-size: 1.5em;
  color: #c00;
}
