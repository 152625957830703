.trustpilot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20rem;
  padding: 20px;
  border-radius: 8px;

  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.trustpilot-widget {
  position: relative;
  width: 100%;
  height: 52px;
  top: 19rem;
  left: -22rem;
  z-index: 100;

  a {
    color: #0073e6;
    font-size: 1.2rem;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;

    &:hover {
      color: #005bb5;
    }

    &:focus {
      outline: none;
      border-bottom: 2px solid #005bb5;
    }
  }
}
@media (max-width: 931px) {
  .trustpilot-widget {
    left: -16rem;
  }
}

@media (max-width: 742px) {
  .trustpilot-widget {
    left: -12rem;
  }
}
@media (max-width: 590px) {
  .trustpilot-widget {
    left: -11rem;
  }
}
@media (max-width: 530px) {
  .trustpilot-widget {
    top: 2rem;
    left: 1rem;
  }
}
