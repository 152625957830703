@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap");

.destination-container {
  margin-top: 90px;
  margin-bottom: 20px;

  text-align: center;
  // h2 {
  //   font-size: 2.5rem; // Larger font size for emphasis
  // }
  h2 {
    font-size: 1.8rem; /* Responsive base size */
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 10%,
      rgba(9, 71, 121, 1) 41%,
      rgba(0, 212, 255, 0.8) 66%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .carousel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 200px;

    .destination {
      width: 230px; // Increase the width for larger images
      height: 230px; // Same height as width to maintain aspect ratio
      border-radius: 50%; // Ensure round shape
      overflow: hidden;
      cursor: pointer;
      transition: transform 0.3s ease, border 0.3s ease;
      border: 3px solid #fff; // Highlight border on hover

      img {
        width: 100%;
        height: 100%;
        object-fit: cover; // Ensures the image covers the area without distortion
        border-radius: 50%; // Ensure the image itself is also perfectly round
        transition: transform ease 0.6s;
      }
      &:hover img {
        transform: scale(1.2);
      }
    }
  }

  .name {
    font-family: "Dancing Script", cursive;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    font-size: 24px;
  }

  @media (max-width: 1603px) {
    .carousel {
      gap: 90px;
    }
  }

  @media (max-width: 1271px) {
    .carousel {
      gap: 40px;
    }
  }

  @media (max-width: 1129px) {
    .carousel .destination {
      width: 200px;
      height: 200px;
    }
  }

  @media (max-width: 768px) {
    .carousel {
      flex-direction: column;
      align-items: center;

      .destination {
        width: 230px;
        height: 230px;
      }
    }
  }

  @media (max-width: 480px) {
    .carousel {
      gap: 40px;

      .destination {
        width: 220px; // Smaller size for very small screens
        height: 220px;
      }
    }
  }
}
