.trips-container {
  width: 80%;
  margin: 120px auto;
  text-align: center;
  margin-bottom: 80px;
  h1 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 10%,
      rgba(9, 71, 121, 1) 41%,
      rgba(0, 212, 255, 0.8) 66%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
  }

  .slick-prev::before,
  .slick-next::before {
    color: rgba(9, 71, 121, 1);
  }

  // .trip-card {
  //   display: flex !important;
  //   flex-direction: column;
  //   align-items: center;
  //   text-align: center;

  //   padding: 0px;
  //   cursor: pointer;
  //   transition: transform 0.3s;
  //   margin: 10px; /* Add margin to create space between cards */

  //   &:hover {
  //     transform: scale(1.05);
  //   }

  //   .trip-image {
  //     width: 80%;
  //     height: 200px;
  //     object-fit: cover;
  //   }

  //   .trip-content {
  //     padding: 10px;

  //     h2 {
  //       font-size: 1.5rem;
  //       margin: 10px 0;
  //     }

  //     p {
  //       margin: 10px 0;
  //     }

  //     .trip-price {
  //       font-weight: bold;
  //       color: #ff6347;
  //     }
  //   }
  // }

  .slick-dots {
    bottom: -30px;
  }
}
.card-container {
  width: 300px;
  height: 200px;
  position: relative;
  border: none;

  display: flex !important;
  flex-direction: column;
  align-items: center;
  text-align: center;

  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  transition: transform 0.3s;
  margin: 10px;

  .trip-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.card-container::before {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0;
  // background: linear-gradient(-45deg, #fc00ff 0%, #00dbde 100%);
  transform: translate3d(0, 0, 0) scale(0.95);
  filter: blur(20px);
}

.trip-card {
  width: 100%;
  height: 100%;
  overflow: hidden;
  gap: 200px;
  border-radius: 4px;
}

.trip-card .img-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: linear-gradient(-45deg, #fc00ff 0%, #00dbde 100%);
  transition: scale 0.6s, rotate 0.6s, filter 1s;
}

.trip-card .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 10%,
    rgba(9, 71, 121, 1) 41%,
    rgba(0, 212, 255, 0.8) 66%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  padding: 20px 24px;
  line-height: 1.5;
  border: none;
  opacity: 0;
  pointer-events: none;
  transform: translateY(50px);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.trip-card .content h2 {
  font-size: 16px;
  font-weight: 750;
}

.trip-card .content p {
  font-size: 13px;
  font-weight: 600;
}

.trip-card:hover .content {
  opacity: 1;
  transform: translateY(0);
}

.trip-card:hover .img-content {
  scale: 2.5;
  rotate: 30deg;
  filter: blur(9px);
}
