@import url("https://fonts.googleapis.com/css2?family=Bokor&family=Devonshire&family=Holtwood+One+SC&display=swap");

.banner {
  height: 420px; // Adjust height as needed
  background-attachment: fixed;
  background-position: center bottom; // Aligns the background to the top
  background-size: cover;
  display: flex;
  align-items: center;
  position: relative;
  color: white; // Assuming white text for visibility
  margin-bottom: 40px;
  // Adding a darker overlay using a pseudo-element
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); // Adjust opacity for darkness
    z-index: 1;
  }

  h1 {
    font-family: "Bokor", system-ui;
    font-weight: 400;
    font-style: normal;
    font-size: 3.5rem; // Large text for the banner title
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); // Shadow for text to ensure visibility
    margin-left: 5%;
    position: relative;
    z-index: 2; // Ensure text is above the overlay
  }
}

@media (max-width: 614px) {
  .banner h1 {
    margin-top: 90px;
    font-size: 2.5rem;
  }
}
