.travel-layout .select-country input {
  background-color: rgb(58, 73, 90) !important;
}

.results-container {
  .title-travel-result {
    border-radius: 50px;
    padding: 10px;
    padding-left: 20px;
    background: linear-gradient(90deg, #020024 0%, rgba(9, 71, 121, 0.451) 91%);
    color: rgb(255, 255, 255);
    height: 40px;
    font-size: 18px;
    margin-bottom: 60px;
  }
}
