@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.about-page {
  .banner {
    background-attachment: fixed;
    height: 400px;
  }

  .about-content {
    padding: 20px;
    padding-left: 14rem;
    padding-right: 14rem;
    margin: 0 auto;
    max-width: 1500px; // Centers the text on larger screens

    @media (max-width: 1360px) {
      padding: 90px;
    }

    h2 {
      font-size: 2rem;
      margin-top: 20px; // Space after the banner
      background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 10%,
        rgba(9, 71, 121, 1) 41%,
        rgba(0, 212, 255, 0.8) 66%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      // font-family: "Dancing Script", cursive;
      // font-optical-sizing: auto;
      // font-weight: 900;
      // font-style: normal;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 1.2rem;
      color: #666;
      line-height: 1.6; // Better readability

      @media (max-width: 750px) {
        font-size: 1.4rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .about-page {
    .about-content {
      padding: 15px;
      h2 {
        font-size: 1.5rem; // Smaller font size on smaller screens
      }
    }
  }
}
