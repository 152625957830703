/* Main layout container */
.travel-layout {
  display: grid;
  grid-template-columns: 336px 1fr;
  gap: 20px;
  padding: 20px;
}

/* Filter section styling */
.filter-container {
  position: sticky;
  height: fit-content;
  backdrop-filter: blur(21.7463px);
  background: linear-gradient(180deg, #020024 0%, rgba(9, 71, 121, 0.451) 91%);
  padding: 20px;
  border-radius: 8px;
  color: #ffffff;
  font-family: Arial, sans-serif;
}

.filter-container h2 {
  font-size: 28px;
  margin-bottom: 15px;
}

.filter-container h4 {
  font-size: 22px;
  margin-top: 62px;
}

.filter-container label {
  display: block;
  margin: 10px 0 5px;
  font-size: 14px;
}

.filter-container input[type="text"],
.filter-container input[type="date"] {
  width: calc(100% - 20px);
  padding: 8px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  background-color: #537d7b;
  color: #ffffff;
  outline: none;
}
.filter-container ::placeholder {
  color: #adadad;
}

.travel-select1 {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.travel-select {
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 15px;
  gap: 10px;
}

.difficulty {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.filter-container input[type="checkbox"] {
  margin-right: 8px;
  accent-color: #ffffff;
}

.filter-container .apply {
  width: 100%;
  padding: 10px;
  background-color: #1a1653;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 5px;

  &:hover {
    background-color: #47437e;
  }
}

.filter-container .reset {
  width: 100%;
  padding: 10px;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  background-color: #c54545;
  margin-bottom: 30px;

  &:hover {
    background-color: #da7878;
  }
}

.grp-travel {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  margin-top: 15px;
  margin-bottom: 10px;
  label {
    padding-top: 4px;
  }
}
.hotel {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  label {
    padding-top: 4px;
  }
}

//checkbox//
.checkbox-wrapper-12 {
  position: relative;
  display: inline-block;
  margin-right: 8px;

  > svg {
    position: absolute;
    top: -30%;
    left: -50%;
    width: 110px;
    pointer-events: none;
  }

  .cbx {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;

    input[type="checkbox"] {
      display: none;
    }

    label {
      cursor: pointer;
      width: 24px;
      height: 24px;
      border: 2px solid #bfbfc0;
      border-radius: 50%;
      display: block;
      position: absolute;
      background: none;
      top: 0;
      left: 0;
      transform: translate3d(0, 0, 0);
      transition: background 0.3s;
    }

    svg {
      position: absolute;
      top: 15px;
      left: 4px;
      z-index: 1;
      pointer-events: none;
    }

    svg path {
      stroke: #fff;
      stroke-width: 3;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 19;
      stroke-dashoffset: 19;
      transition: stroke-dashoffset 0.3s ease;
      transition-delay: 0.2s;
    }

    input:checked + label {
      animation: splash-12 0.6s ease forwards;
    }

    input:checked + label + svg path {
      stroke-dashoffset: 0;
    }
  }
}

@keyframes splash-12 {
  40% {
    background: #183aab;
    box-shadow: 0 -18px 0 -8px #003cff, 16px -8px 0 -8px #003cff,
      16px 8px 0 -8px #003cff, 0 18px 0 -8px #003cff, -16px 8px 0 -8px #003cff,
      -16px -8px 0 -8px #003cff;
  }

  100% {
    background: #183aab;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

/* Results section styling */
.results-container {
  background-color: #ffffff;
  margin-left: 60px;
  padding: 20px;
  border-radius: 8px;
  font-family: Arial, sans-serif;
}

.results-container h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.trip-list {
  width: 90%;
  list-style: none;
  padding: 0;
}

.trip-item {
  border-radius: 50px;
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #f5f5f5;
  margin-bottom: 50px;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;

  // .trip-img {
  //   height: 100%;
  //   width: 40%;
  //   object-fit: contain;
  //   margin-right: 15px;

  img {
    border-radius: 50px;
    width: 35%;
    object-fit: cover;
  }

  .trip-info {
    flex: 1;
    margin-top: 30px;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 30px;
  }
  .indicator-box {
    display: flex;
    align-items: center;
    gap: 5px;

    .indicator {
      height: 35px;
      width: 35px;
    }
  }

  .trip-info h4 {
    font-size: 16px;
    margin: 0 0 10px;
  }
}

.indicator-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px; /* Adjust spacing between icons */
}

.discover-button {
  padding: 8px 12px;
  background-color: #e15b26;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.discover-button:hover {
  background-color: #d14e1c;
}

/* Pagination Controls Styling */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7rem;
}

.pagination-controls button {
  padding: 8px 12px;
  margin: 0 5px;
  border: none;
  border-radius: 4px;
  background-color: #2a4f4d;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination-controls .next-page {
  width: 52px;
}

.pagination-controls button:disabled {
  background-color: #7a7a7a; /* Disabled button color */
  cursor: not-allowed;
}

.pagination-controls button:hover:not(:disabled) {
  background-color: #244542;
}

.pagination-controls span {
  margin: 0 10px;
  font-size: 16px;
  color: #1a1653;
  font-weight: bold;
}

@media (max-width: 778px) {
  .travel-layout {
    display: flex;
    flex-direction: column;
  }
  .trip-item {
    display: flex;
    flex-direction: column;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .results-container {
    margin-left: 0px;
  }
  .trip-list {
    margin-left: 20px;
  }
  .indicator-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px; /* Adjust spacing between icons */
  }
  .indicator-box {
    display: flex;
    align-items: center;
    flex: 1 1 45%;
  }
}
