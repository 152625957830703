.home {
  background-image: url("../../assets/backgroundhome.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
// @media (max-width: 768px) {
//   .home {
//     width: 100%;
//   }
// }
