.packages-header {
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.packages-header h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.packages-header p {
  font-size: 1.2em;
  color: #555;
}

// .packages-container {
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
//   padding: 20px;
// }

// .package-card {
//   display: flex;
//   flex-direction: row;
//   border: 1px solid #ddd;
//   border-radius: 8px;
//   overflow: hidden;
//   width: 100%;
//   max-width: 1000px;
//   margin: 20px auto;
//   transition: transform 0.3s;
//   background-color: #fff;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// }

// .package-card:hover {
//   transform: scale(1.02);
// }

// .package-image {
//   width: 40%;
//   object-fit: cover;
// }

// .package-info {
//   padding: 15px; /* Reduced padding to decrease the height */
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   width: 60%;
// }

// .package-destination {
//   font-size: 0.9em;
//   color: #888;
// }

// .package-info h3 {
//   margin: 8px 0; /* Reduced margin to decrease the height */
//   font-size: 1.5em;
// }

// .package-info p {
//   font-size: 1em;
//   color: #555;
// }

// .package-info ul {
//   list-style: none;
//   padding: 0;
//   margin: 8px 0; /* Reduced margin to decrease the height */
// }

// .package-info ul li {
//   font-size: 1em;
//   color: #555;
// }

// .package-footer {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-top: 15px; /* Reduced margin to decrease the height */
// }

// .package-footer .price {
//   font-size: 1.2em;
//   color: #000;
//   font-weight: bold;
// }

// .package-footer button {
//   background-color: #ff6600;
//   color: #fff;
//   border: none;
//   padding: 10px 20px;
//   border-radius: 4px;
//   cursor: pointer;
//   transition: background-color 0.3s;
// }

// .package-footer button:hover {
//   background-color: #e55d00;
// }

// @media (max-width: 768px) {
//   .package-card {
//     flex-direction: column;
//   }

//   .package-image {
//     width: 100%;
//     height: auto;
//   }
// }
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap");

.card {
  width: 80%;
  height: fit-content;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 40px auto;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  .card-image {
    width: 40%; // Adjust based on your preference
    height: auto;
    object-fit: cover; // Ensures the image covers the assigned area without distortion
  }

  .card-content {
    width: 40%;
    position: relative;
    padding: 20px 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .content-1 {
      z-index: 2;
    }

    .country-name {
      font-size: 24px;
      margin-bottom: 10px;
    }

    .heading {
      font-size: 20px;
      color: #666;
    }

    .description {
      font-family: "Poppins", system-ui;
      font-weight: 400;
      font-style: normal;
      flex-grow: 1;
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 25px;
    }

    .learn-more {
      padding: 10px 20px;
      background-color: #0056b3;
      color: white;
      text-align: center;
      text-decoration: none;
      border-radius: 8px;
      margin-top: 20px;
      align-self: start; // Align button to the start of the flex container
    }
    @media (max-width: 577px) {
      .package-destination {
        font-size: 0.58rem;
      }
      h3 {
        font-size: 0.88rem;
      }
      p {
        font-size: 1.01rem;
      }
      .package-footer {
        p {
          font-size: 1.01rem;
        }
      }
    }
    @media (max-width: 472px) {
      p {
        font-size: 0.91rem;
      }
    }
    @media (max-width: 434px) {
      p {
        font-size: 0.81rem;
      }
      .package-footer {
        p {
          font-size: 0.81rem;
        }
        .price {
          font-size: 0.8rem;
        }
      }
      .package-footer {
        gap: 10px;
      }
    }
  }

  .card-content .package-footer {
    position: relative;
    z-index: 1; // Ensure this is higher than the .circle elements

    button {
      background-color: #ff6600;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 50px;
      cursor: pointer;
      transition: background-color 0.3s;
    }
  }

  .card-content .package-footer button:hover {
    background-color: #080707;
  }

  .package-destination {
    font-size: 0.9em;
    color: #888;
  }

  .package-info h3 {
    margin: 8px 0; /* Reduced margin to decrease the height */
    font-size: 1.5em;
  }

  .package-info p {
    font-size: 1em;
    color: #555;
  }

  .package-info ul {
    list-style: none;
    padding: 0;
    margin: 8px 0; /* Reduced margin to decrease the height */
  }

  .package-info ul li {
    font-size: 1em;
    color: #555;
  }

  .package-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px; /* Reduced margin to decrease the height */
  }

  .package-footer .price {
    font-size: 1.2em;
    color: #000;
    font-weight: bold;
  }

  .package-footer button {
    background-color: #ff6600;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .package-footer button:hover {
    background-color: #e55d00;
  }

  .bg-design {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; // Ensure this is lower than the .content1 element
  }

  .circle {
    position: absolute; // Change from relative to absolute
    margin: 90px 250px;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background-color: #ffbb66;
    filter: blur(15px);
    animation: floating 1500ms infinite linear;
  }

  #bottom {
    background-color: #ff8866;
    left: 110px;
    top: 60px;
    width: 150px;
    height: 150px;
    animation-delay: -800ms;
  }

  #right {
    background-color: #ff2233;
    left: 160px;
    top: -60px;
    width: 50px;
    height: 50px;
    animation-delay: -1800ms;
  }

  @keyframes floating {
    0% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(10px);
    }

    100% {
      transform: translateY(0px);
    }
  }
}

@media (max-width: 748px) {
  .card {
    // height: 450px;
    flex-direction: column;

    .card-image {
      width: 100%;
    }
    .card-content {
      width: 100%;
    }
  }

  .bg-design .circle {
    margin: 99px 25px;
  }
}

@media (max-width: 748px) {
  // .card {
  //   height: 545px;
  // }
}
