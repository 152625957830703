.contact-form-container {
  // background: #f9f9f9;
  // padding: 20px;
  // border-radius: 8px;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  width: 100%;
  max-width: 687px;
  height: 470px;
  border: none;
  padding: 30px;
  border-radius: 50px !important;
  overflow: hidden;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  margin-top: 30px;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  row-gap: 16px;
}

.contact-form-container form {
  display: flex;
  flex-direction: column;
}

.contact-form-container form div {
  margin-bottom: 15px;
}

.contact-form-container form label {
  font-weight: bold;
}

.contact-form-container input,
.contact-form-container textarea {
  width: 90%;
  padding: 10px;
  margin-top: 5px;
  border: 2px solid #ccc;
  border-radius: 14px;
  resize: none;
}
.contact-form-container textarea {
  height: 130px;
}

.contact-form-container button {
  padding: 10px;
  font-size: 1em;
  background-color: #c00;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 14px;
}

.contact-form-container form button:hover {
  background-color: #a00;
}
