.values-container {
  padding: 20px;
  text-align: center;
  background-color: #fff; // Assuming a white background from the image

  h2 {
    margin-bottom: 10px !important;
    color: #333;
    font-size: 24px;
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 10%,
      rgba(9, 71, 121, 1) 41%,
      rgba(0, 212, 255, 0.8) 66%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .values-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); // Three columns for desktop
    gap: 20px;
    align-items: start;
    justify-items: center;

    .value-item {
      background-color: #ffffff; // White background for each card
      padding: 20px;
      border-radius: 50px !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;

      .value-icon {
        font-size: 80px; // Increase icon size
        color: #007bff; // Bluish color
        margin-bottom: 15px;
      }

      h3 {
        color: #4a4a4a;
        font-size: 18px;
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        color: #666;
        text-align: center;
        line-height: 1.5;
      }
    }
  }
}

@media (max-width: 768px) {
  .values-container .values-grid {
    grid-template-columns: 1fr; // Stack on smaller screens
  }
}
