.WhatsApp {
  position: relative;
  z-index: 1000;
  color: green !important;
}

._2qp0Z {
  background-color: rgb(36, 142, 36) !important;
  color: white !important;
  border-radius: 50% !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 5.2) !important;
  transition: transform 0.4s ease !important;

  &:hover {
    background-color: rgb(3, 57, 3) !important;
    transform: scale(1.2);
  }
}
