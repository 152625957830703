body {
  font-family: "Poppins", sans-serif;
  --main-hue: 208;
  --main-color: hsl(var(--main-hue), 92%, 54%);
  --light-main-color: hsl(var(--main-hue), 91%, 55%);
  --text-color: #000;
  --input-bg: hsla(var(--main-hue), 50%, 50%, 6.5%);
  --input-bg-hover: hsla(var(--main-hue), 50%, 50%, 14%);
  --input-text: #8c9aaf;
  --bg-color: #fff;
}

.contact-container {
  color: #353535;
  overflow-x: hidden;

  .banner {
    background-attachment: fixed;
    height: 400px;
  }

  .contact-banner {
    position: relative;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      max-height: 200px;
      box-shadow: 0px 0px 20px rgba(3, 119, 145, 0.5);
    }
  }

  .contact-content {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 20px 90px;

    @media (max-width: 1150px) {
      gap: 80px;
    }

    // @media (max-width: 1024px) {
    //   gap: 100px;
    // }

    @media (max-width: 875px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      padding: 20px 50px;
    }

    .left-side {
      max-width: 650px;
      width: 100%;

      @media (max-width: 768px) {
        gap: 10px;
      }

      .iframe-bg {
        position: relative;
        width: 100%;
        height: 200px;
        margin: 80px auto 130px;

        @media (max-width: 768px) {
          height: 200px;
          margin-left: 30px;
        }

        iframe {
          width: 90%;
          height: 400px;
          border: none;
        }
      }

      .image-container {
        img {
          margin: 0 auto;
          width: 80%;
          height: 280px;

          @media (max-width: 768px) {
            opacity: 0;
            margin-bottom: -150px;
          }
        }
      }
    }
  }

  .contact-form-container {
    max-width: 550px;
    width: 100%;
    height: auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 20px rgba(3, 119, 145, 0.5);

    h2 {
      font-size: 1.5em;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      margin-left: 0.5em;

      @media (max-width: 768px) {
        font-size: 1.5em;
        text-align: center;
      }
    }

    p {
      font-size: 1.1em;
      margin-bottom: 20px;
      margin-left: 0.9em;

      @media (max-width: 768px) {
        font-size: 1em;
        text-align: center;
      }
    }

    form {
      display: flex;
      flex-direction: column;

      .form-row {
        display: flex;
        gap: 20px;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        input {
          flex: 1;
        }
      }

      button {
        display: inline-block;
        margin-top: 10px;
        margin-left: 30px;
        background-color: rgb(0, 217, 255);
        color: #fff;
        padding: 6px 6px;
        border-radius: 5px;
        text-decoration: none;
        font-size: 14px;
        font-weight: bold;
        border: solid 2px rgb(0, 217, 255);
        width: 40%;

        @media (max-width: 768px) {
          width: 100%;
          margin-left: 0;
        }

        &:hover {
          background-color: rgb(255, 255, 255);
          transition: background-color 0.3s ease;
          color: rgb(0, 217, 255);
        }
      }
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.contact-form {
  display: grid !important;
  width: 100% !important;
  margin-top: 1.05rem !important;
  grid-template-columns: repeat(2, 1fr) !important;
  column-gap: 1rem !important;
  row-gap: 0.4rem !important;

  @media (max-width: 768px) {
    grid-template-columns: 1fr !important;
  }
}

.input-wrap {
  position: relative !important;
}

.w-100 {
  grid-column: span 2 !important;

  @media (max-width: 768px) {
    grid-column: span 1 !important;
  }
}

.contact-input {
  width: 100% !important;
  background-color: var(--input-bg) !important;
  border: none !important;
  outline: none !important;
  padding: 1.5rem 0.35rem calc(0.75rem - 2px) 1.35rem !important;
  font-family: inherit !important;
  border-radius: 20px !important;
  color: black !important;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
  border: 2px solid transparent !important;
  box-shadow: 0 0 0 0px hsla(var(--main-hue), 92%, 54%, 0.169) !important;
  transition: 0.3s !important;
}

.contact-input:hover {
  background-color: var(--input-bg-hover) !important;
}

.input-wrap label {
  position: absolute !important;
  top: 50% !important;
  left: calc(1.35rem + 2px) !important;
  transform: translateY(-50%) !important;
  color: #9ca7b6 !important;
  pointer-events: none !important;
  transition: 0.25s !important;
}

.input-wrap .icons {
  position: absolute !important;
  right: calc(1.35rem - 92px) !important;
  top: 59% !important;
  transform: translateY(98%) !important;
  pointer-events: none !important;
  color: var(--input-text) !important;
  font-size: 1.15rem !important;
  transition: 0.3s !important;
}

textarea.contact-input {
  resize: none !important;
  width: 100% !important;
  min-height: 150px !important;
}

textarea.contact-input ~ label {
  top: 1.2rem !important;
  transform: none !important;
}

textarea.contact-input ~ .icons {
  top: 4.3rem !important;
  transform: none !important;
}

.input-wrap.focus .contact-input {
  background-color: var(--bg-color) !important;
  border: 4px solid var(--light-main-color) !important;
  box-shadow: 0 0 0 5px hsla(var(--main-hue), 91%, 55%, 0.11) !important;
}

.input-wrap.focus label {
  color: var(--light-main-color) !important;
}

.input-wrap.focus .icons {
  color: var(--light-main-color) !important;
}

.input-wrap.not-empty label {
  font-size: 0.66rem !important;
  top: 0.75rem !important;
  transform: translateY(0) !important;
}

.contact-buttons {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
  column-gap: 1rem !important;
  margin-top: 1rem !important;

  @media (max-width: 768px) {
    grid-template-columns: 1fr !important;
  }
}

.btn {
  display: inline-block !important;
  padding: 1.1rem 2rem !important;
  background-color: var(--main-color) !important;
}

@media (max-width: 780px) {
  .contact-content {
    padding: -20px 0px;
  }
}
