.gallery-section {
  margin-bottom: 16rem;
}

.album-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 30px;
  width: 80%;
  margin: 100px auto 50px;
}

.album {
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-radius 0.3s ease;
}

.album-cover {
  width: 100%;
  height: 290px;
  object-fit: cover;
  border-radius: 10px;
  filter: brightness(0.65);
}

.album-info {
  position: absolute;
  width: 100%;
  height: 20%;
  bottom: 25px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 9px 15px;
  border-radius: 5px;
}

.album-info h3 {
  margin-top: -5px;
  margin-bottom: 0px;
  font-size: 18px;
}

.album-info p {
  margin: 0;
  font-size: 12px;
}

.image-gallery {
  width: 90%;
  margin: 66px auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 30px;
}

.image-gallery img {
  width: 100%;
  height: 16rem;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-radius 0.3s ease;
}

.image-gallery img:hover {
  transform: scale(1.09);
  border-radius: 20px;
  box-shadow: 0 32px 75px rgba(68, 77, 136, 0.2);
}

.full-img {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.full-img img {
  object-fit: contain;
  width: 70%;
  height: 100vh;
}

.full-img span {
  position: absolute;
  top: 5%;
  right: 5%;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 90px;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  background: #124921;
  border: none;
  outline: none;
  margin: 0 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgb(67, 147, 121);
  }
}

//loader
.triangle1 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 50px 0 0;
  border-color: #8086e0 transparent transparent transparent;
  margin: 0 auto;
  animation: shk1 1s ease-in-out infinite normal;
}

.triangle2 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 50px 50px;
  border-color: transparent transparent #6554b388 transparent;
  margin: -50px auto 0;
  animation: shk2 1s ease-in-out infinite alternate;
}
@keyframes shk1 {
  0% {
    transform: rotate(-360deg);
  }

  100% {
  }
}

@keyframes shk2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
  }
}

.text {
  color: #949494;
  margin: 30px auto;
  text-align: center;
  font-weight: 500;
  letter-spacing: 4px;
}

// .tab.active {
//   background-color: #354a3a;
// }

@media (max-width: 768px) {
  .image-gallery {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .image-gallery img {
    width: 100%;
    height: 12rem;
  }
}

.back-albm {
  appearance: none;
  background-color: transparent;
  border: 0.125em solid #1a1a1a;
  border-radius: 0.9375em;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 3.75em;
  min-width: 0;
  outline: none;
  padding: 1em 2.3em;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
  position: relative;
  left: 40%;
}

.back-albm:disabled {
  pointer-events: none;
}

.back-albm:hover {
  color: #fff;
  background-color: #1a1653;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.back-albm:active {
  box-shadow: none;
  transform: translateY(0);
}
