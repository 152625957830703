@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.about-container {
  padding: 20px;
  max-width: 1200px; // Limit the width for large screens
  margin: auto; // Center the container on the page

  .about-header {
    text-align: center; // Center the header text

    h1 {
      font-size: 2rem; /* Responsive base size */
      background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 10%,
        rgba(9, 71, 121, 1) 41%,
        rgba(0, 212, 255, 0.8) 66%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    h2 {
      font-family: "Dancing Script", cursive;
      font-optical-sizing: auto;
      font-weight: 900;
      font-style: normal;
      font-size: 2.2rem; // Larger font size for emphasis
      color: #222; // Darker color for more impact
      margin-top: 10px; // Space between the subtitle and title
    }
  }

  .about-description {
    // font-family: "Dancing Script", cursive;
    // font-optical-sizing: auto;
    // font-weight: 900;
    font-family: "Poppins", system-ui;
    font-weight: 500;

    font-size: 20px;
    font-style: normal;
    margin-top: 20px; // Space between header and paragraph
    // font-size: 1.85rem; // Standard font size for the paragraph
    line-height: 1.6; // Improved readability for longer text
    text-align: justify; // Justify text for a clean look
    color: #555; // Slightly lighter text color for the description
  }

  @media (max-width: 1168px) {
    .about-description {
      padding: 40px;
    }
  }

  @media (max-width: 768px) {
    // Responsive adjustments for tablets and mobile
    .about-header {
      h1 {
        font-size: 1.2rem; // Smaller font size for smaller screens
      }

      h2 {
        font-size: 2rem; // Reduce the title size for smaller screens
      }
    }

    .about-description {
      font-size: 0.9rem; // Slightly smaller text for mobile readability
    }
  }
}

// @media (max-width: 768px) {
//   .about-container {
//     width: max-content;
//   }
// }
