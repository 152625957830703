/* Ensure all elements use border-box for sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

footer {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(30, 29, 29, 0.4)),
    /* Apply a dark overlay */ url("../../assets/footer.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
  padding: 40px 0;
  padding-top: 120px; /* Add padding to account for the image height */
  font-size: 17px;
  font-weight: 600;
}

.footer-top-image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px; /* Adjust this height according to your image height */
  overflow: hidden;
  z-index: 2; /* Ensure it stays above the background */
}

.footer-top-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer-container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column; /* Stack columns vertically */
  align-items: center; /* Center align columns */
  max-width: 1600px;
  margin: auto;
  padding: 0 15px;
}

.footer-item {
  display: flex;
  justify-content: center;
  gap: 200px;
}

.footer-column {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  min-width: 250px;
}

.footer-logo {
  margin-top: -80px;
  margin-bottom: -90px;
  width: 12rem;
  height: auto;
}

.tagline {
  margin-top: -20px;
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
}

.social-media-icons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.address p {
  font-weight: bold;
  font-size: 2px;
}

.social-icon img {
  width: 30px;
  height: auto;
  margin: 0 10px;
  filter: brightness(0) invert(1);
}

.address h3,
.quick-links h3 {
  color: gold;
  margin-bottom: 20px;
}

.address a,
.quick-links ul li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.2s;
}

.address a:hover,
.quick-links ul li a:hover {
  color: gold;
}

.quick-links ul {
  display: flex;
  gap: 60px;
  list-style: none;
  padding: 0;
}

.footer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

@media (max-width: 768px) {
  .footer-logo {
    padding-top: 30px;
    width: 120px;
  }

  .footer-column {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .tagline {
    font-size: 0.9em;
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    flex: 1 1 100%;
    max-width: 100%;
    margin: 10px 0;
    align-items: center;
  }

  .footer-logo {
    width: 100px;
  }

  .tagline {
    font-size: 0.85em;
  }

  .social-icon img {
    width: 25px;
  }
  .footer-item {
    flex-direction: column;
    align-items: center;
  }
}
.footer-copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
  color: #fff;
  font-size: 16px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.footer-copyright hr {
  width: 80%;
  border: none;
  border-radius: 10px;
  height: 3px;
  background: #fff;
}
@media (max-width: 680px) {
  .quick-links ul {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
@media (max-width: 680px) {
  .quick-links ul {
    font-size: 0.9rem;
  }
  .footer-copyright {
    font-size: 0.8rem;
  }
}
