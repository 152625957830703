@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.card {
  width: 80%;
  height: 334px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 40px auto;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  .card-image {
    width: 40%; // Adjust based on your preference
    height: auto;
    object-fit: cover; // Ensures the image covers the assigned area without distortion
  }

  .card-content {
    width: 40%;
    position: relative;
    padding: 20px 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    .country-name {
      font-size: 24px;
      margin-bottom: 10px;
    }

    .heading {
      font-size: 20px;
      color: #666;
    }

    .description {
      font-family: "Poppins", system-ui;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 25px;
    }

    .learn-more {
      padding: 10px 20px;
      background-color: #0056b3;
      color: white;
      text-align: center;
      text-decoration: none;
      border-radius: 8px;
      margin-top: 20px;
      align-self: start; // Align button to the start of the flex container
    }
  }

  .content1 {
    position: relative;
    z-index: 1; // Ensure this is higher than the .circle elements
  }

  .bg-design {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; // Ensure this is lower than the .content1 element
  }

  .circle {
    position: absolute; // Change from relative to absolute
    margin: 90px 250px;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background-color: #ffbb66;
    filter: blur(15px);
    animation: floating 1500ms infinite linear;
  }

  #bottom {
    background-color: #ff8866;
    left: 110px;
    top: 60px;
    width: 150px;
    height: 150px;
    animation-delay: -800ms;
  }

  #right {
    background-color: #ff2233;
    left: 160px;
    top: -60px;
    width: 50px;
    height: 50px;
    animation-delay: -1800ms;
  }

  @keyframes floating {
    0% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(10px);
    }

    100% {
      transform: translateY(0px);
    }
  }
}

@media (max-width: 1445px) {
  .card {
    height: 450px;
  }
  .bg-design .circle {
    margin: 99px 25px;
  }
}

@media (max-width: 748px) {
  // .card {
  //   height: 545px;
  // }
}

@media (max-width: 655px) {
  .card-content .content1 {
    .description {
      font-size: 15px !important;
      line-height: 1.5;
      margin-bottom: 25px;
    }
  }
}

@media (max-width: 444px) {
  .card {
    height: 250px;
  }
  .card-content .content1 {
    .description {
      font-size: 10px !important;
      line-height: 1.5;
      margin-bottom: 25px;
    }
  }
}
