.activities-header {
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.activities-header h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.activities-header p {
  font-size: 1.2em;
  color: #555;
}

.activities-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

@media (max-width: 1200px) {
  .activities-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .activities-grid {
    grid-template-columns: 1fr;
  }
}

.activity-card {
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s;
}

.activity-card:hover {
  transform: scale(1.05);
}

.activity-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.activity-card h3 {
  margin: 10px;
  font-size: 1.5em;
}

.activity-card p {
  margin: 10px;
  font-size: 1em;
  color: #555;
}
