.banner-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 3s ease-out, filter 0.5s ease-in-out,
    opacity 0.5s ease-in-out;
}

.banner-image.blur {
  transform: scale(1.5);
  transition: filter 5s ease, transform 5s ease;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease-out, filter 0.5s ease-out,
    opacity 0.5s ease-out;
  animation: zoomBlur 8s ease infinite; /* Infinite animation for continuous effect */
}

@keyframes zoomBlur {
  0%,
  100% {
    transform: scale(1);
    filter: blur(0);
  }
  50% {
    transform: scale(1.1);
    filter: blur(0px);
  }
}

.text-overlay {
  position: absolute;
  bottom: 5%;
  left: 5%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 10;
  text-align: left;
  width: 50%;
  max-width: 800px;
  min-height: 100px;
  transition: opacity 0.5s ease-in-out;
}

h1 {
  font-size: 1.7em;
}

.description {
  font-size: 1em;
  line-height: 1.5em;
}

.learn-more {
  padding: 10px 20px;
  font-size: 1em;
  background-color: transparent;
  color: rgb(0, 213, 255);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.learn-more:hover {
  background-color: grey;
  color: white;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 2vw;
  z-index: 100;
  opacity: 0.7;
  transition: opacity 0.3s, background-color 0.3s;
}

.nav-button:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.8);
}

.nav-button.left {
  opacity: 0;
  left: 10px;
}

.nav-button.right {
  opacity: 0;
  right: 10px;
}

@media (max-width: 768px) {
  .text-overlay {
    width: 95%;
    min-height: 80px;
    bottom: 10px;
    left: 5%;
  }

  h1 {
    font-size: 1.2em;
  }

  .description {
    font-size: 0.9em;
  }

  .learn-more {
    font-size: 0.7em;
  }

  .nav-button {
    font-size: 16px;
  }
}

// @media (max-width: 768px) {
//   .banner-container {
//     width: max-content;
//   }
// }
